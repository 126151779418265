@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,700&display=swap");
.popup {
  background-color: rgba(000, 000, 000, 0.6);
}

/* body {
  background-image: url(./images/background.png);
  background-repeat: no-repeat;
} */
.body {
  font-family: "Open Sans", sans-serif !important;
}
.NewsForYou {
  padding: 30px 15px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 35px 0 0 35px;
}

ul a.active li,
ul + div a.active svg {
  color: #e00201;
  font-weight: bold;
}

.topCompound .swiper-button-prev {
  left: -10px;
}
.topCompound .swiper-button-next {
  right: -6px;
}
.swiper-pagination {
  margin: -15px 0;
}
.swiper-pagination-bullet {
  width: 20px !important;
  height: 20px !important;
  border-radius: 6px !important;
}

.loadingPro div {
  background-color: #dadada;
  border-radius: 5px;
  animation: load 1.1s infinite;
}

@keyframes load {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

:root {
  --swiper-navigation-color: blue;
}

.swiper-button-next {
  color: green;
  /* background: url("next-arrow.png") no-repeat center; */
}

.swiper-button-next:after {
  color: red;
  margin-left: -10px;
}
.swiper-button-prev:after {
  color: red;
  margin-right: -10px;
}

.my-swiper .swiper-button-next,
.my-swiper .swiper-button-prev {
  color: red; /* Change the color to your desired color */
}

.bgImg {
  background-image: url("/src/images/bg.png");
  background-position: left;
  background-size: unset;
  background-repeat: no-repeat;
}
.bgs {
  background-image: url("/src/images/bgs.png");
  background-position: right;
  background-size: unset;
  background-repeat: no-repeat;
}
.bgImgC {
  background-image: url("/src/images/Layer_x0020_1.png");
  background-position: center;
  background-size: unset;
  background-repeat: no-repeat;
}
.bgCon {
  background-image: url("/src/images/image 20.png");
  background-position: center;
  background-size: unset;
  background-repeat: no-repeat;
}
.bgCon {
  background-image: url("/src/images/Rectangle 56.png");
  background-position: center;
  background-size: unset;
  background-repeat: no-repeat;
}
@media not all and (min-width: 640px) {
  .bgImg {
    background-image: url("/src/images/bg.png");
    background-position: center;
    background-size: unset;
    background-repeat: no-repeat;
  }
}
